import router from './router'
import store from './store'
import {
	Message
} from 'element-ui'
import Storage from '@/utils/storage.js' //缓存操作封装

const whiteList = ['/login', '/auth-redirect', '/bind', '/register']

router.beforeEach((to, from, next) => {
	var token = Storage.get('token')
	if (token) {
		/* has token*/
		if (to.path === '/login') {
			next({path: '/'})
		} else {
			// if (store.getters.roles.length === 0) {
			// 	isRelogin.show = true
			// 	// 判断当前用户是否已拉取完user_info信息
			// 	store.dispatch('GetInfo').then(() => {
			// 		isRelogin.show = false
			// 		store.dispatch('GenerateRoutes').then(accessRoutes => {
			// 			// 根据roles权限生成可访问的路由表
			// 			router.addRoutes(accessRoutes) // 动态添加可访问路由表
			// 			next({
			// 				...to,
			// 				replace: true
			// 			}) // hack方法 确保addRoutes已完成
			// 		})
			// 	}).catch(err => {
			// 		store.dispatch('LogOut').then(() => {
			// 			Message.error(err)
			// 			next({
			// 				path: '/'
			// 			})
			// 		})
			// 	})
			// } else {
			// 	next()
			// }
				next()
		}
	} else {
		// 没有token
		if (whiteList.indexOf(to.path) !== -1) {
			// 在免登录白名单，直接进入
			next()
		} else {
			next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
		}
	}
	next()
})

router.afterEach(() => {})
