<template>
	<div id="app">
		<transition :name="transitionName">
			<router-view />
		</transition>

	</div>
</template>

<script>
	export default {
		name: 'App',
		watch: {
			$route(to, from) {
				// console.log(to);
				// 切换动画
				// 页面之间的跳转一般由路由中的level来实现
				let level = this.$route.meta.level // 监听路由的层级
				// if (to.name == 'index') {
				if (from.meta.level > level) {
					this.transitionName = 'slide-right'
				} else {
					this.transitionName = 'slide-left'
				}

			}
		},
		data() {
			return {
				transitionName: 'slide-right' // 初始过渡动画方式
			}
		}
	}
</script>

<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
	}

	* {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}

	.slide-left-enter,
	.slide-right-leave-to {
		opacity: 0;
		transform: translateX(100%)
	}

	.slide-left-leave-to,
	.slide-right-enter {
		opacity: 0;
		transform: translateX(-100%)
	}

	.slide-left-enter-active,
	.slide-left-leave-active,
	.slide-right-enter-active,
	.slide-right-leave-active {
		transition: .5s;
		position: absolute;
		top: 0;
	}

	::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 5px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 5px;
	}

	::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 4px;
		background-color: #BD7A3A;
		background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
	}

	::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: #ededed;
		border-radius: 4px;
	}
</style>
