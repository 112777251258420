import request from '@/utils/request'


export function jobCollect(query) {
	return request({
		url: '/api/front/job/jobCollect',
		method: 'get',
		params: query
	})
}

export function delJob(id) {
	return request({
		url: '/api/front/job/' + id,
		method: 'delete',
	})
}

export function editJob(data) {
	return request({
		url: '/api/front/job',
		method: 'put',
		data: data
	})
}

export function addJob(data) {
	return request({
		url: '/api/front/job',
		method: 'post',
		data: data
	})
}

export function jobList(query) {
	return request({
		url: '/api/front/job/list',
		method: 'get',
		params: query
	})
}

export function jobDeptDetail(jobDeptId) {
	return request({
		url: '/api/front/job/dept/' + jobDeptId,
		method: 'get',
	})
}

export function jobTimeList() {
	return request({
		url: '/api/front/job/timeList',
		method: 'get',
	})
}

export function getWeather(query) {
	return request({
		url: '/api/front/weather',
		method: 'get',
		params: query
	})
}

export function getDict(type) {
	return request({
		url: '/api/front/dict/data/type/' + type,
		method: 'get',
	})
}

export function getDept(query) {
	return request({
		url: '/api/front/user/list/bydept',
		method: 'get',
		params: query
	})
}

export function getUser(query) {
	return request({
		url: '/api/front/user/byname',
		method: 'get',
		params: query
	})
}

export function getWorkList(query) {
	return request({
		url: '/api/front/user/task',
		method: 'get',
		params: query
	})
}
export function editWork(data) {
	return request({
		url: '/api/front/user/task',
		method: 'put',
		data: data
	})
}
export function delWork(taskId) {
	return request({
		url: '/api/front/user/task/' + taskId,
		method: 'delete'
	})
}
export function addWork(data) {
	return request({
		url: '/api/front/user/task',
		method: 'post',
		data: data
	})
}






export function getPlanList(query) {
	return request({
		url: '/api/front/user/plan',
		method: 'get',
		params: query
	})
}

export function editPlan(data) {
	return request({
		url: '/api/front/user/plan',
		method: 'put',
		data: data
	})
}

export function addPlan(data) {
	return request({
		url: '/api/front/user/plan',
		method: 'post',
		data: data
	})
}

export function delPlan(planId) {
	return request({
		url: '/api/front/user/plan/' + planId,
		method: 'delete'
	})
}




export function getNotes(query) {
	return request({
		url: '/api/front/user/notes',
		method: 'get',
		params: query
	})
}

export function saveNotes(data) {
	return request({
		url: '/api/front/user/notes',
		method: 'post',
		data: data
	})
}

export function getAnalysis(query) {
	return request({
		url: '/api/front/user/analysis',
		method: 'get',
		params: query
	})
}

export function getWeeklyPlan(query) {
	return request({
		url: '/api/front/user/file/byType',
		method: 'get',
		params: query
	})
}

export function getTemplateList(query) {
	return request({
		url: '/api/front/user/file/template/list',
		method: 'get',
		params: query
	})
}

export function saveAnalysis(data) {
	return request({
		url: '/api/front/user/analysis',
		method: 'post',
		data: data
	})
}


export function getFiles(query) {
	return request({
		url: '/api/front/user/file',
		method: 'get',
		params: query
	})
}
export function delFile(id) {
	return request({
		url: '/api/front/user/file/' + id,
		method: 'delete'
	})
}
// plan
export function getProject() {
	return request({
		url: '/api/front/project',
		method: 'get'
	})
}

export function getProjectDetail(projectId) {
	return request({
		url: '/api/front/project/' + projectId,
		method: 'get'
	})
}
export function getAllName() {
	return request({
		url: '/api/front/user/nameList',
		method: 'get'
	})
}
export function updatePlan(data) {
	return request({
		url: '/api/front/project/save',
		method: 'post',
		data: data
	})

}
export function projectTask() {
	return request({
		url: '/api/front/project/project/task',
		method: 'get'
	})

}
// 上面的表格{{baseUrl}}/api/front/user/framework?userId=4
export function frameworkList(id) {
	return request({
		url: '/api/front/user/framework?userId=' + id,
		method: 'get',
	})
}
// 保存上面的表
export function saveFramework(data) {
	return request({
		url: '/api/front/user/framework',
		method: 'post',
		data: data
	})
}

export function getProjectWorkList(query) {
	return request({
		url: '/api/front/projectWork/list',
		method: 'get',
		params: query
	})
}


export function saveProjectWork(data) {
	return request({
		url: '/api/front/projectWork/save',
		method: 'post',
		data: data
	})
}

export function changeProjectStage(data) {
	return request({
		url: '/api/front/project/change/stage',
		method: 'post',
		data: data
	})
}


export function saveWorkExec(data) {
	return request({
		url: '/api/front/workExec/save',
		method: 'post',
		data: data
	})
}


export function login(data) {
	return request({
		url: '/login',
		method: 'post',
		data: data
	})
}

export function getInfo(query) {
	return request({
		url: '/getInfo',
		method: 'get',
		params: query
	})
}

//判断权限，传入userId
export function limit(query) {
	return request({
		url: '/api/front/user/limit',
		method: 'get',
		params: query
	})
}


// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
	const data = {
		oldPassword,
		newPassword
	}
	return request({
		url: '/system/user/profile/updatePwd',
		method: 'put',
		params: data
	})
}


//   创建文件夹
export function saveCeaterFold(data) {
	return request({
		url: '/api/front/user/file/create/folder',
		method: 'post',
		data: data
	})
}

// 修改文件名
export function editFile(data) {
	return request({
		url: '/api/front/user/file/update',
		method: 'put',
		data: data
	})
}
// 获取所有移动文件夹
export function getAllfold(query) {
	return request({
		url: '/api/front/user/file/folder/tree',
		method: 'get',
		params: query
	})
}
// 移动文件
export function moveFile(tagetFold, data) {
	return request({
		url: '/api/front/user/file/move/' + tagetFold + '/' + data,
		method: 'put',
	})
}
// 获取mind list
export function getMindList(query) {
	return request({
		url: '/api/front/user/mind/list',
		method: 'get',
		params: query
	})
}

//   创建mind
export function ceaterNewMind(data) {
	return request({
		url: '/api/front/user/mind',
		method: 'post',
		data: data
	})
}
// delMind
export function delMind(userId, id) {
	return request({
		url: '/api/front/user/mind?userId=' + userId + '&id=' + id,
		method: 'delete'
	})
}

//   修改mind
export function updateMind(data) {
	return request({
		url: '/api/front/user/mind',
		method: 'put',
		data: data
	})
}